import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useLayoutEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselControl,
  Badge,
} from "reactstrap";
import { formatDate } from "../../../actions";
import { RenderButton } from "../../../components";
import {
  setContentBeingEdited,
  setEditPostModalVisibility,
  updateContent,
} from "../actions";

const CampaignContentCard = ({
  content,
  isPreview,

  updateContent,
  setContentBeingEdited,
  setEditPostModalVisibility,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [expandedCaption, setExpandedCaption] = useState(false);
  const [expandedFeedback, setExpandedFeedback] = useState(false);

  const feedbackRef = useRef(null);

  const categoryOptions = [
    { label: "Fashion", value: "fashion" },
    { label: "Travel", value: "travel" },
    { label: "Beauty", value: "beauty" },
    { label: "Wellness/Fitness", value: "wellnessfitness" },
    { label: "Food", value: "food" },
    { label: "Home", value: "home" },
    { label: "Productivity", value: "productivity" },
    { label: "Reading", value: "reading" },
  ];

  const handleEditContent = (content) => {
    setContentBeingEdited(content);
    setEditPostModalVisibility(true);
  };

  const slides = content?.imageUrls?.map((url, index) => {
    // return createImageSlides({ url, index, setAnimating });
    return (
      <CarouselItem
        key={`carousel-item-${index}`}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <div
          className="blurred-backdrop"
          style={{
            backgroundImage: `url(${url})`,
          }}
        />
        <img
          className="d-block w-100"
          src={url}
          onLoad={(e) => {
            e.target.classList.add("loaded");
          }}
          alt={"Uploaded media"}
        />
        <div className="img-skeleton">
          <Skeleton height="100%" width="100%" />
        </div>
      </CarouselItem>
    );
  });

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const handleFocus = () => {
    // setTimeout(() => {
    const feedback = feedbackRef?.current;
    if (feedback) {
      feedback.scrollTop = feedback.clientHeight;
    }
    // }, 100);
  };

  useLayoutEffect(() => {
    if (expandedFeedback) {
      handleFocus();
    }
  }, [expandedFeedback]);

  return (
    <div className="campaign-content-card-wrapper">
      <Card className={`campaign-content-card ${isPreview ? "preview" : ""}`}>
        {content?.status !== "draft" && !isPreview && (
          <CardHeader>
            {/* <RenderButton
              id="feedback-popover"
              tabIndex="0"
              className="feedback-btn d-flex float-right btn-outline-primary m-0 d-none hidden"
              type="button"
              title={
                content?.feedback?.length
                  ? "View Admin Feedback"
                  : "No Admin Feedback Yet"
              }
              disabled={!content?.feedback?.length}
            >
              <ChatBubbleOvalLeftIcon height="25" width="25" />
            </RenderButton> */}
            <button
              className="see-more bold my-2 text-left expand-toggle"
              onClick={() => {
                setExpandedCaption(false);
                setExpandedFeedback(!expandedFeedback);
              }}
            >
              {expandedFeedback ? "Hide" : "View"} Admin Feedback{" "}
              <ChevronDownIcon
                height="20"
                width="20"
                className={expandedFeedback ? "expanded" : "collapsed"}
              />
            </button>
            {/* <FeedbackPopoverWithRef
              placement="bottom"
              trigger="focus"
              target="feedback-popover"
              className="feedback-parent-container"
              isOpen={popoverIsOpen}
              toggle={() => setPopoverIsOpen(!popoverIsOpen)}
              onFocus={handleFocus}
              innerRef={feedbackPopoverRef}
            >
              <PopoverHeader>Admin Feedback</PopoverHeader>
              <PopoverBody className="feedback-container popover-scrollable">
                {content?.feedback?.map((feedback, index) => {
                  return (
                    <div className="feedback-item" key={index}>
                      <p className="message mb-1">{feedback.message}</p>
                      <p className="date small">
                        {formatDate(feedback.created, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}
                      </p>
                    </div>
                  );
                })}
              </PopoverBody>
            </FeedbackPopoverWithRef> */}
          </CardHeader>
        )}
        <CardBody className="content-body">
          <div
            className={`feedback-overlay ${
              expandedFeedback ? "expanded" : "collapsed"
            }`}
            ref={feedbackRef}
          >
            <div className="feedback-container">
              {content?.feedback?.length ? (
                <>
                  {content?.feedback?.map((feedback, index) => {
                    return (
                      <div className="feedback-item" key={index}>
                        <p className="message mb-1">{feedback.message}</p>
                        <p className="date small">
                          {formatDate(feedback.created, {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}
                        </p>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className="no-feedback">No feedback has been added yet.</p>
              )}
            </div>
          </div>
          <div
            className={`expanded-caption-overlay ${
              expandedCaption ? "expanded" : "d-none"
            }`}
          >
            <p>{content?.caption}</p>
            <button
              className="see-less bold mb-3 text-left mx-3"
              onClick={() => {
                setExpandedCaption(false);
              }}
            >
              Close
            </button>
          </div>
          <div className="content-image-container">
            <Carousel
              enableTouch={true}
              interval={false}
              activeIndex={activeIndex}
              next={next}
              previous={previous}
            >
              {slides?.length > 1 && (
                <CarouselIndicators
                  items={slides}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
              )}
              {slides}
              {slides?.length > 1 && (
                <>
                  <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                  />
                </>
              )}
            </Carousel>
          </div>
          {content?.caption || content?.title ? (
            <>
              <div className="content-text-container">
                {content?.category && (
                  <Badge
                    color="neutral"
                    className={"badge-soft-neutral mx-3 mb-3"}
                  >
                    {
                      categoryOptions.find(
                        (obj) => obj.value === content?.category,
                      )?.label
                    }
                  </Badge>
                )}
                {content?.title && <h4 className="mx-3">{content?.title}</h4>}
                <p className="mx-3 mb-1">{content?.caption}</p>
                <button
                  className="see-more bold mb-3 text-left mr-3"
                  onClick={() => {
                    setExpandedCaption(true);
                  }}
                >
                  See More
                </button>
              </div>
            </>
          ) : (
            <div className="content-text-skeleton mx-3">
              <Skeleton className="mb-1" height="20%" />
              <Skeleton height="60%" />
            </div>
          )}
          {!isPreview && (
            <>
              {content?.status === "draft" && (
                <Row>
                  <Col className="mx-3 mt-2 button-split">
                    <RenderButton
                      className="btn btn-outline-primary btn-default modal-button mt-1"
                      onClick={() => handleEditContent(content)}
                    >
                      Edit Post
                    </RenderButton>

                    <RenderButton
                      title="Submit post for approval"
                      className="btn btn-primary btn-default modal-button mt-1"
                      onClick={() =>
                        updateContent({
                          _id: content?._id,
                          submitForApproval: true,
                        })
                      }
                    >
                      Submit
                    </RenderButton>
                  </Col>
                </Row>
              )}
              {content?.status === "rejected" && (
                <Row>
                  <Col className="mx-3 mt-2 button-split">
                    <RenderButton
                      className="btn btn-outline-primary btn-default modal-button mt-1"
                      onClick={() => handleEditContent(content)}
                    >
                      Edit Post
                    </RenderButton>

                    <RenderButton
                      title="Resubmit post for approval"
                      className="btn btn-primary btn-default modal-button mt-1"
                      onClick={() =>
                        updateContent({
                          _id: content?._id,
                          submitForApproval: true,
                        })
                      }
                    >
                      Resubmit
                    </RenderButton>
                  </Col>
                </Row>
              )}
              {content?.status === "approved" && (
                <Row>
                  <Col className="mx-3 mt-2">
                    <RenderButton
                      title="Enter URL to Live Post"
                      className="btn btn-primary btn-default modal-button mt-1 float-right"
                      onClick={() => handleEditContent(content)}
                    >
                      Complete
                    </RenderButton>
                  </Col>
                </Row>
              )}
              {content?.status === "link rejected" && (
                <Row>
                  <Col className="mx-3 mt-2">
                    <RenderButton
                      title="Enter URL to Live Post"
                      className="btn btn-primary btn-default modal-button mt-1 float-right"
                      onClick={() => handleEditContent(content)}
                    >
                      Update Link
                    </RenderButton>
                  </Col>
                </Row>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};
export default connect(mapStateToProps, {
  setEditPostModalVisibility,
  setContentBeingEdited,
  updateContent,
})(CampaignContentCard);
